<template>
  <div>
    <div class="profile is_page">
      <div class="profiles_banner">
        <img :src="user.background ? user.background.url : 'https://static.bcdcnt.net/assets/images/group/group-cover-1.jpg'" :alt="user.title">
      </div>
      <div class="profiles_content">
        <div class="profile_avatar">
          <div class="profile_avatar_holder">
            <img :src="user.avatar ? user.avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" :alt="user.title">
          </div>
          <div class="icon_change_photo" hidden>
            <ion-icon name="camera" class="text-xl"></ion-icon>
          </div>
        </div>
        <div class="profile_info">
          <h1 style="text-transform: inherit">
            {{ user.username }}
          </h1>
          <p>
            <span v-for="(role,index) in user.roles" :key="'role' + index"
                  :class="'inline-flex items-center justify-center px-2 py-1 text-xs leading-none rounded-full text-white mr-2 ' + getRoleClass(role.alias)">{{
                role.name
              }} <span v-if="role.custom_title" class="ml-1">({{ role.custom_title }})</span></span>
          </p>
          <p>
            <span v-if="user.yob">{{ new Date().getFullYear() - user.yob }} tuổi</span>
            <span> · {{ numberFormat(user.views) }} lượt xem</span>
          </p>
          <p v-if="false && user.gender">{{ user.gender === 'M' ? 'Nam' : user.gender === 'F' ? 'Nữ' : '' }}</p>
          <p v-if="user.gifts && user.gifts.length">
            <img :src="gift.product.icon" :uk-tooltip="'title: ' + gift.post_title" width="15" :key="'gift' + gift.ID" v-for="(gift) in user.gifts"/>
          </p>
        </div>
        <div class="flex items-center space-x-4" v-if="user.point">
          <div class="flex items-center -space-x-4">
            <div class="w-24 h-24 rounded-full flex justify-center items-center bg-green-500 text-white font-semibold"
                 uk-tooltip="Điểm cống hiến">+{{ numberFormat(user.point) }} điểm
            </div>
          </div>
        </div>
      </div>

      <nav class="responsive-nav border-t -mb-0.5 lg:pl-2">
        <div class="flex justify-between lg:border-t flex-col-reverse lg:flex-row">
          <nav class="responsive-nav pl-2 is_ligh -mb-0.5 border-transparent">
            <ul uk-switcher="connect: #timeline-tab; animation: uk-animation-fade">
              <li><a href="#">Hoạt động</a></li>
              <li><a href="#">Bình luận<span>{{ comments.paginatorInfo.total }}</span></a></li>
              <li><a href="#">Thành viên hát<span>{{ karaokes.paginatorInfo.total }}</span></a></li>
              <li><a href="#">Upload<span>{{ uploads.paginatorInfo.total }}</span></a></li>
              <li><a href="#">Cống hiến<span>{{ points.paginatorInfo.total }}</span></a></li>
            </ul>
          </nav>
        </div>
      </nav>
    </div>

    <div class="uk-switcher lg:mt-8 mt-4" id="timeline-tab">
      <div class="md:space-x-6 lg:mx-16" id="activities" v-on:scroll.passive="onScroll">
        <div class="card p-3 mt-5" v-if="activities.edges && activities.edges.length">
          <activity-item v-for="(activity, index) in activities.edges" :key="'activity' + index"
                         :activity="activity.node"
                         :user="user"/>
        </div>
      </div>
      <div class="md:space-x-6 lg:mx-16">
        <div class="card p-3 mt-5" uk-lightbox="toggle:.lightbox" v-if="comments.data && comments.data.length">
          <div class="mb-5 flex" v-for="comment in comments.data" :key="'comment' + comment.id">
            <div class="w-10 h-10 rounded-full relative flex-shrink-0">
              <router-link :to="{name: 'UserDetail', params: {id: comment.user.id}}" v-if="comment.user">
                <img
                    :src="comment.user && comment.user.avatar ? comment.user.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-4.jpg'"
                    alt="" class="absolute h-full rounded-full w-full object-cover">
              </router-link>
              <img
                  src="https://static.bcdcnt.net/assets/images/avatars/avatar-4.jpg"
                  alt="" class="absolute h-full rounded-full w-full" v-if="!comment.user">
            </div>
            <div class="">
              <div
                  class="text-gray-700 py-2 px-3 rounded-3xl bg-gray-100 relative lg:ml-5 ml-2 dark:bg-gray-800 dark:text-gray-100">
                <h4 class="text-base m-0 font-semibold">
                  <router-link
                      :to="{name: 'SongDetail', params: {id: comment.object.id, slug: comment.object.slug, prefix: getPrefixFromSongType(comment.object['__typename'])}}"
                      v-if="comment.object && isSongable(comment.object['__typename'])" :title="comment.object.title">
                    {{ comment.object.title }}
                  </router-link>
                  <router-link
                      :to="{name: 'PeopleDetail', params: {slug: comment.object.slug, prefix: getRoutePrefixPeople(comment.object['__typename'])}}"
                      v-if="comment.object && isPeople(comment.object['__typename'])" :title="comment.object.title">
                    {{ comment.object.title }}
                  </router-link>
                  <router-link
                      :to="{name: 'DocumentDetail', params: {id: comment.object.id, slug: comment.object.slug}}"
                      v-if="comment.object && comment.object['__typename'] === 'Document'"
                      :title="comment.object.title">
                    {{ comment.object.title }}
                  </router-link>
                  <router-link
                      :to="{name: 'DiscussionDetail', params: {id: comment.object.id, slug: comment.object.slug}}"
                      v-if="comment.object && comment.object['__typename'] === 'Discussion'"
                      :title="comment.object.title">
                    {{ comment.object.title }}
                  </router-link>
                  <router-link
                      :to="{name: 'PlaylistDetail', params: {id: comment.object.id, slug: comment.object.slug}}"
                      v-if="comment.object && comment.object['__typename'] === 'Playlist'"
                      :title="comment.object.title">
                    {{ comment.object.title }}
                  </router-link>
                  <router-link :to="{name: 'SheetDetail', params: {id: comment.object.id, slug: comment.object.slug}}"
                               v-if="comment.object && comment.object['__typename'] === 'Sheet'"
                               :title="comment.object.title">
                    {{ comment.object.title }}
                  </router-link>
                  <router-link :to="{name: 'PageDetail', params: {slug: comment.object.slug}}"
                               v-if="comment.object && comment.object['__typename'] === 'Page'"
                               :title="comment.object.title">
                    {{ comment.object.title }}
                  </router-link>
                </h4>

                <show-more :content="comment.content" :length="300" class=""></show-more>

                <div class="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
              </div>
              <div class="text-xs flex items-center space-x-3 mt-2 ml-5">
                <span class="flex items-center">
                  <a href="javascript:void(0)" v-on:click="loveOrUnlove(comment)"
                     :uk-tooltip="'title: ' + getUserLoves(comment)" class="text-red-600 mr-1">
                    <i class="far fa-heart" v-if="!comment.hasLoved"></i>
                    <i class="fas fa-heart text-red-600" v-if="comment.hasLoved"></i>
                  </a>
                  {{ comment.loves && comment.loves.paginatorInfo.total > 0 ? comment.loves.paginatorInfo.total : '' }}
                </span>
                <span>{{ timeago(comment.created_at) }}</span>
                <a href="javascript:void(0)" v-on:click="deleteComment(comment)"
                   v-if="currentUser && ((comment.user && parseInt(comment.user.id) === parseInt(currentUser.id)) || (parseInt(currentUser.profile_id) === 1 || parseInt(currentUser.profile_id) === 2))">Xóa</a>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <v-pagination
                class="justify-content-center"
                v-if="comments.paginatorInfo.lastPage > 1"
                v-model="comments.paginatorInfo.currentPage"
                :pages="comments.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadComments"
            />
          </div>
        </div>
      </div>
      <div class="md:space-x-6 lg:mx-16">
        <div class="card p-3">
          <div class="grid md:grid-cols-2 divide divide-gray-200 gap-x-4 mt-4" v-if="karaokes.data.length">
            <song-item v-for="song in karaokes.data" :song="song" prefix="thanh-vien-hat" :key="'song' + song.id"/>
          </div>
          <div class="mt-4">
            <v-pagination
                class="justify-content-center"
                v-if="karaokes.paginatorInfo.lastPage > 1"
                v-model="karaokes.paginatorInfo.currentPage"
                :pages="karaokes.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadKaraokes"
            />
          </div>
        </div>
      </div>
      <div class="md:space-x-6 lg:mx-16">
        <div class="card p-3">
          <div class="grid md:grid-cols-2 divide divide-gray-200 gap-x-4 mt-4" v-if="uploads.data.length">
            <div v-for="upload in uploads.data" :key="'upload' + upload.id">
              <song-item v-if="upload.object && isSongable(upload.object['__typename'])" :song="upload.object ? upload.object : upload"
                       :prefix="getPrefixFromSongType(upload.object ? upload.object['__typename'] : '')"/>
              <document-item v-if="upload.object && upload.object['__typename'] === 'Document'" :document="upload.object ? upload.object : null"/>
            </div>
          </div>
          <div class="mt-4">
            <v-pagination
                class="justify-content-center"
                v-if="uploads.paginatorInfo.lastPage > 1"
                v-model="uploads.paginatorInfo.currentPage"
                :pages="uploads.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadUploads"
            />
          </div>
        </div>
      </div>
      <div class="md:space-x-6 lg:mx-16">
        <div class="card p-3">
          <ul v-if="points.data.length">
            <point-item v-for="point in points.data" :point="point" :user="user" :key="'point' + point.id"/>
          </ul>
          <div class="mt-4">
            <v-pagination
                class="justify-content-center"
                v-if="points.paginatorInfo.lastPage > 1"
                v-model="points.paginatorInfo.currentPage"
                :pages="points.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadPoints"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import SongItem from "../SongItem";
import DocumentItem from "../DocumentItem";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {
  getUserLoves,
  hasLoved,
  timeago,
  getPrefixFromSongType,
  getRoutePrefixPeople,
  isSongable,
  isPeople,
  logActivity,
  getRoleClass,
  getCustomTitle,
  lightBoxable,
  convertOembed,
  makePlayTime,
  censorBadWords, numberFormat
} from "../../core/services/utils.service";
import ShowMore from "../ShowMore";
import {getUser} from "../../core/services/jwt.service";
import PointItem from "../point/PointItem";
import ActivityItem from "../activity/ActivityItem";

export default {
  name: "UserDetail",
  components: {
    ActivityItem,
    PointItem, DocumentItem,
    SongItem, VPagination, ShowMore
  },
  data() {
    return {
      id: null,
      currentUser: null,
      user: {
        username: "",
        fullname: "",
        yob: "",
        gender: "",
        point: 0,
        views: 0,
        avatar: null
      },
      karaokes: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      activities: {
        edges: [],
        pageInfo: {
          startCursor: "",
          endCursor: ""
        }
      },
      comments: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      uploads: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      points: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadUser() {
      let query = `query($id: ID!) {
        user(id: $id) {
          id
          username
          fullname
          profile_id
          yob
          gender
          point
          views
          is_active
          is_banned
          gifts
          avatar {
            url
          }
          background {
            url
          }
          roles {
            name
            alias
            group_type
            userRolePivot {
              role
              custom_title
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.user) {
              if (data.data.user.is_active === "N" || data.data.user.is_banned === "Y") {
                this.$router.push({name: '404', params: {catchAll: '404'}});
                return;
              }

              this.user = data.data.user;
              if (this.user.gifts) {
                this.user.gifts = JSON.parse(this.user.gifts);
              }
              if (this.user.roles && this.user.roles.length) {
                this.user.roles.map((role) => {
                  role.custom_title = getCustomTitle(role.userRolePivot, role);
                });
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadKaraokes() {
      let query = `query($id: ID!, $page: Int) {
        user(id: $id) {
          karaokes(first: 20, orderBy: [{ column: "views", order: DESC }], page: $page) {
            data {
              id
              title
              slug
              views
              thumbnail {
                url
              }
              sheet {
                composers(first: 20, orderBy: [{column: "sheet_composer.order", order: ASC}]) {
                  data {
                    id
                    title
                    slug
                  }
                }
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {id: this.id, page: this.karaokes.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.user && data.data.user.karaokes) {
              this.karaokes = data.data.user.karaokes;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadUploads() {
      let query = `query($id: ID!, $page: Int) {
        user(id: $id) {
          uploads(first: 20, orderBy: [{ column: "id", order: DESC }], page: $page, where: {AND: [{column: "status", value: "approved"}]}) {
            data {
              title
              object {
                __typename
                id
                title
                slug
                views
                ... on Song {
                  thumbnail {
                    url
                  }
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Folk {
                  thumbnail {
                    url
                  }
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Instrumental {
                  thumbnail {
                    url
                  }
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Poem {
                  thumbnail {
                    url
                  }
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Karaoke {
                  thumbnail {
                    url
                  }
                  user {
                    id
                    username
                    avatar {
                        url
                      }
                  }
                }
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {id: this.id, page: this.uploads.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.user && data.data.user.uploads) {
              this.uploads = data.data.user.uploads;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadComments() {
      let query = `query($id: ID!, $page: Int) {
        user(id: $id) {
          comments(first: 10, page: $page, orderBy: [{column: "id", order: DESC}], where: {AND: [{column: "status", value: 1}]}) {
            data {
              id
              content
              created_at
              nickname
              object {
                __typename
                ... on Song {
                  id
                  title
                  slug
                }
                ... on Folk {
                  id
                  title
                  slug
                }
                ... on Instrumental {
                  id
                  title
                  slug
                }
                ... on Karaoke {
                  id
                  title
                  slug
                }
                ... on Poem {
                  id
                  title
                  slug
                }
                ... on Composer {
                  id
                  title
                  slug
                }
                ... on Artist {
                  id
                  title
                  slug
                }
                ... on Poet {
                  id
                  title
                  slug
                }
                ... on Recomposer {
                  id
                  title
                  slug
                }
                ... on Document {
                  id
                  title
                  slug
                }
                ... on Discussion {
                  id
                  title
                  slug
                }
                ... on Playlist {
                  id
                  title
                  slug
                }
                ... on Sheet {
                  id
                  title
                  slug
                }
                ... on Page {
                  id
                  title
                  slug
                }
                ... on VovProgram {
                  id
                  title
                  slug
                }
                ... on Role {
                  id
                  name
                  slug
                }
              }
              user {
                username
                id
                avatar {
                  url
                }
              }
              loves(first: 100) {
                data {
                  user_id
                  user {
                    id
                    username
                  }
                }
                paginatorInfo {
                  currentPage
                  lastPage
                  total
                }
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }`;

      ApiService.graphql(query, {id: this.id, page: this.comments.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.user && data.data.user.comments) {
              this.comments = data.data.user.comments;
              if (this.comments.data && this.comments.data.length) {
                this.comments.data.map((comment) => {
                  comment.hasLoved = this.hasLoved(comment);
                  comment.content = lightBoxable(convertOembed(makePlayTime(censorBadWords(comment.content))));
                });
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadPoints() {
      let query = `query($id: ID!, $page: Int) {
        user(id: $id) {
          points(first: 10, page: $page, orderBy: [{column: "id", order: DESC}]) {
            data {
              point
              reward_type
              created_at
              reason
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }`;

      ApiService.graphql(query, {id: this.id, page: this.points.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.user && data.data.user.points) {
              this.points = data.data.user.points;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadActivities() {
      let query = `query($user_id: Mixed) {
        activities(
          first: 10
          after: ""
          where: { AND: [{ column: "user_id", value: $user_id }, { column: "action", value: ["update_lyric", "comment", "love_comment", "create_sheet", "create_document", "create_song", "create_folk", "create_instrumental", "create_karaoke", "create_poem", "approve_upload", "reject_upload"], operator: IN }] }
          orderBy: [{ column: "id", order: DESC }]
        ) {
          edges {
            node {
              action
              extra
              created_at
              object {
                __typename
                ... on Song {
                  id
                  title
                  slug
                }
                ... on Document {
                  id
                  title
                  slug
                }
                ... on Sheet {
                  id
                  title
                  slug
                }
                ... on Upload {
                  id
                  title
                  type
                  user {
                    username
                  }
                }
                ... on Comment {
                  user {
                    username
                  }
                }
                ... on Discussion {
                  id
                  title
                  slug
                }
              }
              extra
            }
          }
          pageInfo {
            startCursor
            endCursor
          }
        }
      }
      `;

      ApiService.graphql(query, {user_id: this.id})
          .then(({data}) => {
            if (data.data && data.data.activities) {
              this.activities = data.data.activities;
              this.activities.edges.map((item) => {
                if (item.node && item.node.extra) {
                  item.node.extra = JSON.parse(item.node.extra);
                }
              });
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    getUserLoves(comment) {
      return getUserLoves(comment);
    },
    hasLoved(comment) {
      return hasLoved(comment, this.currentUser);
    },
    timeago(x) {
      return timeago(x);
    },
    getPrefixFromSongType(type) {
      type = type.toLowerCase();
      return getPrefixFromSongType(type);
    },
    getRoutePrefixPeople(type) {
      type = type.toLowerCase();
      return getRoutePrefixPeople(type);
    },
    isSongable(type) {
      return isSongable(type);
    },
    isPeople(type) {
      return isPeople(type);
    },
    deleteComment(comment) {
      let r = confirm("Bạn có chắc chắn muốn xóa bình luận này?");
      if (r === true) {
        let query = `mutation($id: ID!) {
        deleteComment(id: $id) {
          id
        }
      }`;

        ApiService.graphql(query, {id: comment.id})
            .then(({data}) => {
              if (data.data && data.data.deleteComment) {
                this.loadComments();
              } else {
                alert(data.errors[0].message);
              }
            })
            .catch((response) => {
              alert(response.message);
            });
      }
    },
    loveOrUnlove(comment) {
      if (comment.hasLoved) {
        this.unlove(comment);
      } else {
        this.love(comment);
      }
    },
    love(comment) {
      comment.hasLoved = true;
      comment.loves.paginatorInfo.total++;

      let query = `mutation($id: ID!) {
        love(lovable_id: $id, lovable_type: "comment") {
          id
          likes
        }
      }`;

      ApiService.graphql(query, { id: comment.id })
        .then(({ data }) => {
          if (data.data && data.data.love) {
            comment.loves.paginatorInfo.total = data.data.love.likes;
            this.loadComments();
          } else {
            comment.hasLoved = false;
            comment.loves.paginatorInfo.total--;
            if (data.errors[0].message == "Unauthenticated.") {
              window.$("#loginModal").addClass("uk-open");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    unlove(comment) {
      comment.hasLoved = false;
      comment.loves.paginatorInfo.total--;

      let query = `mutation($id: ID!) {
        unlove(lovable_id: $id, lovable_type: "comment") {
          id
          likes
        }
      }`;

      ApiService.graphql(query, { id: comment.id })
        .then(({ data }) => {
          if (data.data && data.data.unlove) {
            comment.loves.paginatorInfo.total = data.data.unlove.likes;
            this.loadComments();
          } else {
            comment.hasLoved = true;
            comment.loves.paginatorInfo.total++;
            if (data.errors[0].message == "Unauthenticated.") {
              window.$("#loginModal").addClass("uk-open");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    reRender() {
      this.loadUser();
      this.loadActivities();
      this.loadKaraokes();
      this.loadComments();
      this.loadUploads();
      this.loadPoints();
      logActivity("view", "user", this.id);
    },
    getRoleClass(alias) {
      return getRoleClass(alias);
    },
    getCustomTitle(pivot, role) {
      return getCustomTitle(pivot, role);
    },
    numberFormat(x) {
      return numberFormat(x);
    },
    onScroll(el) {
      console.log("onScroll.........");
      if ((el.srcElement.offsetHeight + el.srcElement.scrollTop) >= el.srcElement.scrollHeight) {
        this.loadActivities();
      }
    }
  },
  mounted() {
    this.currentUser = getUser();
    this.id = this.$route.params.id;
    this.reRender();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.reRender();
    window.scrollTo(0, 0);
    next();
  }
}
</script>
